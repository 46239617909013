<template>
	<div>
        <h2>{{ $t("monte.ajout_tiers_mare") }} {{ horse.horse_nom }}</h2>
		<HorseAjout 
            :add-or-edit-ready.sync="addOrEditReady"
            :disable-button.sync="disableButton"
            ref="tiersHorseAjout"
            :tiershorse_id="tiershorse_id"
            :horse="horse"
            :tiers_horse="tiers_horse"
        />
        <b-button v-if="addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
	</div>
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import MonteShutters from '@/mixins/shutters-manager/Monte'

	export default {
		name: 'MareTiers',
		mixins: [Shutter, MonteShutters],
		props: ['horse'],
		data () {
			return {
                addOrEditReady: true,
                disableButton: false,
                processing: false,
                tiers_horse: null,
                tiershorse_id: null,
			}
		},

		methods: {
			async checkForm() {
				this.processing = true
                let result = await this.$refs.tiersHorseAjout.checkForm()
                if(result) {
                	this.ok()
                }
			}
		},

		components: {
            HorseAjout : () => import('@/components/Tiers/HorseAjout')
		}
	}

</script>